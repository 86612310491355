<template>
    <div class="about page normal-page">
        <div class="page-banner">
            <img
                class="desktop-banner"
                src="../assets/about/banner.jpg"
                alt=""
            >
            <img
                class="mobile-banner"
                src="../assets/about/mobile/banner-mobile.jpg"
                alt=""
            >
        </div>
        <div class="web-content">
            <div class="page-structure">
                <PageMenu :menu-data="pageMenu" />
                <div class="page-content about-content">
                    <div class="ceo-words block">
                        <div class="block-title">
                            <div class="dot" />
                            {{ $t('about.ceo.title') }}
                        </div>
                        <div class="words-box">
                            <div class="left-box">
                                <div class="text text-justify">
                                    <p>
                                        {{ $t('about.ceo.text[0]') }}
                                    </p>
                                    <p>
                                        {{ $t('about.ceo.text[1]') }}
                                    </p>
                                    <p>
                                        {{ $t('about.ceo.text[2]') }}
                                    </p>
                                </div>
                            </div>
                            <div class="image-box">
                                <img
                                    src="../assets/about/ceo.jpg"
                                    alt=""
                                >
                                <div class="text">
                                    <span class="name">{{ $t('about.ceo.name') }}</span>
                                    <span class="job-title">{{ $t('about.ceo.job') }} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="video">
                        <iframe
                            class="video-frame"
                            src="https://www.youtube.com/embed/Ztt8LyKrDjo?controls=0"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write;
            encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        />
                    </div>
                    <Anchor :id="'four-main'" />
                    <div
                        id=""
                        class="four-main block"
                    >
                        <div class="block-title">
                            <div class="dot" />
                            {{ $t('about.fourMain.title') }}
                        </div>
                        <div
                            v-for="item in fourMain"
                            :key="item.title"
                            class="item"
                        >
                            <img
                                :src="item.img"
                                alt=""
                            >
                            <div class="text-box">
                                <div class="sub-title">
                                    {{ $t(item.title) }}
                                </div>
                                <div class="text">
                                    {{ $t(item.text) }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="core block">
                        <div class="block-title">
                            <div class="dot" />
                            {{ $t('about.core.title') }}
                        </div>
                        <div class="core-content">
                            <div class="core-desktop">
                                <div class="left">
                                    <div
                                        v-for="item in coreLeft"
                                        :key="item.number"
                                    >
                                        <div
                                            :ref="item.ref"
                                            class="item"
                                            :class="item.revealClass"
                                            :style="{ color: item.color }"
                                        >
                                            <div class="item-title">
                                                <div class="number">
                                                    {{ item.number }}
                                                </div>
                                                <div class="word">
                                                    {{ $t(item.title) }}
                                                </div>
                                            </div>
                                            <div class="text">
                                                {{ $t(item.text) }}
                                            </div>
                                        </div>
                                        <div class="item empty-item" />
                                    </div>
                                </div>
                                <div class="line">
                                    <img
                                        src="../assets/about/core-line.png"
                                        alt=""
                                    >
                                </div>
                                <div class="right">
                                    <div
                                        v-for="item in coreRight"
                                        :key="item.number"
                                    >
                                        <div class="item empty-item" />
                                        <div
                                            :ref="item.ref"
                                            class="item"
                                            :class="item.revealClass"
                                            :style="{ color: item.color }"
                                        >
                                            <div class="item-title">
                                                <div class="number">
                                                    {{ item.number }}
                                                </div>
                                                <div class="word">
                                                    {{ $t(item.title) }}
                                                </div>
                                            </div>
                                            <div class="text">
                                                {{ $t(item.text) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="core-mobile">
                                <div class="mobile-content">
                                    <div
                                        v-for="item in coreMobile"
                                        :key="item.number"
                                        :ref="`${item.ref}-mobile`"
                                        class="item"
                                        :class="item.revealClass"
                                        :style="{ color: item.color }"
                                    >
                                        <div class="item-title">
                                            <div class="number">
                                                {{ item.number }}
                                            </div>
                                            <div class="word">
                                                {{ $t(item.title) }}
                                            </div>
                                        </div>
                                        <div class="text">
                                            {{ $t(item.text) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="line">
                                    <img
                                        src="../assets/about/core-line.png"
                                        alt=""
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <Anchor :id="'partner'" />
                    <div class="partner block">
                        <div class="block-title">
                            <div class="dot" />
                            {{ $t('about.partner.title') }}
                        </div>
                        <div class="partner-content">
                            <div class="top">
                                <div class="item line-heigh-1">
                                    <div class="label">
                                        {{ $t('about.partner.label[0]') }}｜
                                    </div>
                                    <a
                                        class="link line-heigh-1"
                                        href="https://www.tzuchi.org.tw/"
                                        target="_blank"
                                    >
                                        <img
                                            src="../assets/about/partner/main.png"
                                            alt=""
                                        >
                                    </a>
                                </div>
                                <div class="item">
                                    <div class="label">
                                        {{ $t('about.partner.label[1]') }}｜
                                    </div>
                                    <a
                                        class="link line-heigh-1"
                                        href="https://taipei.impacthub.net/"
                                        target="_blank"
                                    >
                                        <img
                                            src="../assets/about/partner/impact.png"
                                            alt=""
                                        >
                                    </a>
                                </div>
                            </div>
                            <div class="box">
                                <a
                                    v-for="(item, index) in partners"
                                    :key="index"
                                    :href="item.link"
                                    class="item"
                                    target="_blank"
                                >
                                    <img
                                        :src="item.image"
                                        alt=""
                                    >
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import PageMenu from '../components/PageMenu.vue';

export default {
    components: {
        PageMenu,
    },
    data() {
        return {
            pageMenu: {
                // eslint-disable-next-line global-require
                icon: require('../assets/about/menu-icon.png'),
                title: 'about.pageMenu.title',
                items: [
                    {
                        type: 'link',
                        text: 'about.pageMenu.items.item1.text',
                        link: '#',
                    },
                    {
                        type: 'link',
                        text: 'about.pageMenu.items.item2.text',
                        link: '#four-main',
                    },
                    {
                        type: 'link',
                        text: 'about.pageMenu.items.item3.text',
                        link: '#partner',
                    },
                ],
            },
            fourMain: [
                {
                    // eslint-disable-next-line global-require
                    img: require('../assets/about/impact-1.png'),
                    title: 'about.fourMain.name[0]',
                    text: 'about.fourMain.text[0]',
                },
                {
                    // eslint-disable-next-line global-require
                    img: require('../assets/about/impact-2.png'),
                    title: 'about.fourMain.name[1]',
                    text: 'about.fourMain.text[1]',
                },
                {
                    // eslint-disable-next-line global-require
                    img: require('../assets/about/impact-3.png'),
                    title: 'about.fourMain.name[2]',
                    text: 'about.fourMain.text[2]',
                },
                {
                    // eslint-disable-next-line global-require
                    img: require('../assets/about/impact-4.png'),
                    title: 'about.fourMain.name[3]',
                    text: 'about.fourMain.text[3]',
                },
            ],
            coreLeft: [
                {
                    number: '01',
                    title: 'about.core.name[0]',
                    text: 'about.core.text[0]',
                    color: '#626262',
                    revealClass: 'reveal-text-color1',
                    ref: 'core01',
                },
                {
                    number: '03',
                    title: 'about.core.name[2]',
                    text: 'about.core.text[2]',
                    color: '#038499',
                    revealClass: 'reveal-text-color3',
                    ref: 'core03',
                },
                {
                    number: '05',
                    title: 'about.core.name[4]',
                    text: 'about.core.text[4]',
                    color: '#C5981B',
                    revealClass: 'reveal-text-color5',
                    ref: 'core05',
                },
            ],
            coreRight: [
                {
                    number: '02',
                    title: 'about.core.name[1]',
                    text: 'about.core.text[1]',
                    color: '#033D4F',
                    revealClass: 'reveal-text-color2',
                    ref: 'core02',
                },
                {
                    number: '04',
                    title: 'about.core.name[3]',
                    text: 'about.core.text[3]',
                    color: '#F97E2B',
                    revealClass: 'reveal-text-color4',
                    ref: 'core04',
                },
            ],
            partners: [
                {
                    link: 'https://www.yda.gov.tw/index.aspx',
                    // eslint-disable-next-line global-require
                    image: require('../assets/about/partner/1.png'),
                },
                {
                    link: 'https://www.tcust.edu.tw/',
                    // eslint-disable-next-line global-require
                    image: require('../assets/about/partner/2.png'),
                },
                {
                    link: 'http://www.tzuchiculture.org.tw/',
                    // eslint-disable-next-line global-require
                    image: require('../assets/about/partner/3.png'),
                },
                {
                    link: 'https://www.tcu.edu.tw/',
                    // eslint-disable-next-line global-require
                    image: require('../assets/about/partner/4.png'),
                },
                {
                    link: 'https://sert.tw/',
                    // eslint-disable-next-line global-require
                    image: require('../assets/about/partner/5.png'),
                },
                {
                    link: 'https://www.iwomenweb.org.tw/default.html',
                    // eslint-disable-next-line global-require
                    image: require('../assets/about/partner/6.png'),
                },
                {
                    link: 'https://www.topkeycsr.org.tw/',
                    // eslint-disable-next-line global-require
                    image: require('../assets/about/partner/7.png'),
                },
                {
                    link: 'http://blab.tw/',
                    // eslint-disable-next-line global-require
                    image: require('../assets/about/partner/8.png'),
                },
                {
                    link: 'https://www.twnpos.org.tw/',
                    // eslint-disable-next-line global-require
                    image: require('../assets/about/partner/9.png'),
                },
                {
                    link: 'https://www.asusfoundation.org/',
                    // eslint-disable-next-line global-require
                    image: require('../assets/about/partner/10.png'),
                },
                {
                    link: 'https://www.jwcpas.com.tw/',
                    // eslint-disable-next-line global-require
                    image: require('../assets/about/partner/11.png'),
                }],
        };
    },
    computed: {
        coreMobile() {
            const list = _.union(this.coreLeft, this.coreRight);
            return _.sortBy(list, ['number']);
        },
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    mounted() {
        this.handleScroll();
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        clickPageMenu() {
            this.pageMenuOpen = !this.pageMenuOpen;
        },
        handleScroll() {
            const { scrollY } = window;
            const windowHeight = window.innerHeight;
            const positionY = scrollY + windowHeight / 2;
            this.coreLeft.forEach((item) => {
                this.handleCoreAnimation(item.ref, positionY);
            });
            this.coreRight.forEach((item) => {
                this.handleCoreAnimation(item.ref, positionY);
            });
            this.coreMobile.forEach((item) => {
                this.handleCoreAnimation(`${item.ref}-mobile`, positionY);
            });
        },
        handleCoreAnimation(el, positionY) {
            const elRef = this.$refs[el][0];
            if (positionY >= elRef.offsetTop) {
                elRef.classList.add('reveal-text');
            } else {
                elRef.classList.remove('reveal-text');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import url('../scss/effect.scss');
.about {
  .is-mobile {
    display: none;
  }
  .about-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .block {
      padding: 0 15px;
      width: 100%;
    }
    .words-box {
      display: flex;
      margin-bottom: 50px;
      .left-box {
        border-left: 3px solid $color-orange;
        padding: 5px 0 0 20px;
        margin-right: 85px;

        .text {
          font-weight: 700;
          font-size: 21px;
          letter-spacing: 0.1em;
          line-height: 2;
          color: $color-gray;
        }
      }

      .image-box {
        img {
          width: 350px;
        }

        .text {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          margin-top: 20px;

          .name {
            color: $color-blue;
            font-weight: 700;
            font-size: 28px;
            letter-spacing: 0.1em;
            margin-right: 20px;
            line-height: 1;
          }

          .job-title {
            color: $color-gray;
            font-weight: 700;
            font-size: 21px;
            letter-spacing: 0.1em;
            line-height: 1;
          }
        }
      }
    }

    .video {
      position: relative;
      padding-bottom: 50.54%; /* 16:9 */
      width: 100%;
      height: 0;
      margin-bottom: 50px;

      .video-frame {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .four-main {
      margin-bottom: 50px;
      .item {
        display: flex;
        padding-bottom: 35px;
        border-bottom: 1px solid $color-orange;
        align-items: flex-start;

        img {
          width: 66px;
          margin-right: 45px;
        }

        .text-box {
          color: $color-gray;
          .sub-title {
            font-weight: 700;
            font-size: 25px;
            letter-spacing: 0.1em;
            line-height: 1;
            margin-bottom: 25px;
          }

          .text {
            font-weight: 700;
            font-size: 16px;
            line-height: 2;
            letter-spacing: 0.1em;
          }
        }
      }

      .item:not(:last-child) {
        margin-bottom: 50px;
      }
    }

    .core {
      .core-mobile {
        display: none;
      }

      .core-desktop {
        display: flex;
      }
      .core-content {
        display: flex;
        justify-content: center;

        .empty-item {
          height: 69px;
        }
      }
      .line {
        img {
          width: 60px;
        }
      }

      .left {
        padding-top: 40px;
        margin-right: 95px;
        .item {
          align-items: flex-end;
        }
      }

      .right {
        padding-top: 40px;
        margin-left: 95px;
        .item {
          align-items: flex-start;
        }
      }

      .item {
        display: flex;
        flex-direction: column;
        margin-bottom: 68px;

        .item-title {
          display: flex;
          font-weight: 700;
          font-size: 35px;
          letter-spacing: 0.2em;
          margin-bottom: 14px;
          line-height: 1;
          .number {
            margin-right: 20px;
          }
        }

        .text {
          font-weight: 700;
          font-size: 21px;
          line-height: 1;
          letter-spacing: 0.1em;
          white-space: pre-line;
        }
      }

      .item-1 {
        color: #626262;
      }
      .item-2 {
        color: #033d4f;
      }
    }

    .partner {
      margin-bottom: 70px;
      .partner-content {
        padding-left: 50px;
      }
      img {
        flex-shrink: 0;
        width: 180px;
      }
      .top {
        display: flex;
        margin-bottom: 20px;
        flex-wrap: wrap;
        .item {
          display: flex;
          text-decoration: none;
          color: $color-gray;
          margin-right: 55px;
          line-height: 1;

          img {
            margin-left: 5px;
          }
          .label {
            display: flex;
            align-items: center;
            font-weight: 700;
            font-size: 21px;
            line-height: 1;
            letter-spacing: 0.1em;
            color: $color-gray;
          }
        }
      }
      .box {
        display: flex;
        flex-wrap: wrap;
        .item {
          margin-right: 45px;
          margin-bottom: 30px;
          display: block;
          line-height: 1;
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .about {
    .about-content {
      .words-box {
        .image-box {
          img {
            width: 300px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1140px) {
  .about {
    .about-content {
      .words-box {
        .image-box {
          img {
            width: 260px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .about {
    .is-desktop {
      display: none;
    }
    .is-mobile {
      display: block;
    }

    .about-content {
      .words-box {
        flex-wrap: wrap-reverse;
        justify-content: center;

        .image-box {
          margin-bottom: 20px;
        }

        .left-box {
          margin-right: 0;
          .text {
            font-size: 16px;
          }
        }
      }

      .four-main {
        .item {
          img {
            flex-shrink: 0;
            flex-grow: 0;
          }
        }
      }

      .core {
        .core-content {
          .left {
            margin-right: 60px;
          }
          .right {
            margin-left: 60px;
          }
          .item {
            .item-title {
              font-size: 28px;
              line-height: 1;
            }
            .text {
              font-size: 16px;
              line-height: 1;
            }
          }
        }
      }

      .partner {
        .partner-content {
          padding-left: 0;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .about {
    .about-content {
      .words-box {
        .image-box {
          img {
            width: 200px;
          }
          .text {
            .name {
              font-size: 21px;
            }

            .job-title {
              font-size: 16px;
            }
          }
        }

        .left-box {
          margin-right: 0;
          .text {
            font-size: 14px;
          }
        }
      }

      .four-main {
        .item {
          padding-bottom: 20px;

          img {
            width: 40px;
            margin-right: 18px;
          }

          .text-box {
            .sub-title {
              font-size: 16px;
              margin-bottom: 10px;
            }
            .text {
              font-size: 14px;
            }
          }
        }
      }

      .core {
        .core-desktop {
          display: none;
        }
        .core-mobile {
          display: flex;

          .mobile-content {
            margin-right: 15px;
            margin-top: 30px;
          }
        }

        .line {
          img {
            width: 45px;
          }
        }

        .core-content {
          .item {
            margin-bottom: 50px;
            .item-title {
              font-size: 24px;
              justify-content: flex-end;
            }
            .text {
              font-size: 14px;
              justify-content: flex-end;
            }
          }
        }
      }

      .partner {
        .partner-content {
          .top {
            margin-bottom: 20px;
            .item {
              margin-right: 0;
              .label {
                font-size: 14px;
              }
            }
          }

          .box {
            .item {
              margin-right: 20px;
            }
            img {
              width: 130px;
            }
          }
        }
      }
    }
  }
}
</style>
